import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import SectionNav from './section-nav';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import styled from '@emotion/styled';
import useMount from 'react-use/lib/useMount';
import { breakpoints, colors, smallCaps } from 'gatsby-theme-apollo-core';

const Container = styled.div({
  display: 'flex',
  alignItems: 'flex-start'
});

const MainContent = styled.div({
  flexGrow: 1,
  width: 0,
  maxWidth: '100ch'
});

const tableBorder = `1px solid ${colors.divider}`;
const table = {
  marginBottom: '1.45rem',
  border: tableBorder,
  borderSpacing: 0,
  borderRadius: 4,
  [['th', 'td']]: {
    padding: 16,
    borderBottom: tableBorder
  },
  'tbody tr:last-child td': {
    border: 0
  },
  th: {
    ...smallCaps,
    fontSize: 13,
    fontWeight: 'normal',
    color: colors.text2,
    textAlign: 'inherit'
  },
  td: {
    verticalAlign: 'top',
    code: {
      whiteSpace: 'normal'
    }
  }
};

const BodyContent = styled.div({
  // style all anchors with an href and no prior classes
  // this helps avoid anchors with names and styled buttons
  'a[href]:not([class])': {
    color: colors.primary,
    ':hover': {
      textDecoration: 'underline'
    },
    code: {
      color: 'inherit'
    }
  },
  [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
    '&[id]::before': {
      // inspired by https://css-tricks.com/hash-tag-links-padding/
      content: "''",
      display: 'block',
      visibility: 'hidden',
      pointerEvents: 'none'
    },
    ':not(:hover) a svg': {
      visibility: 'hidden'
    },
    'a.anchor': {
      ':hover': {
        opacity: colors.hoverOpacity
      },
      svg: {
        fill: colors.primary
      }
    }
  },
  [['h2', 'h3', 'h4']]: {
    ':not(:first-of-type)': {
      marginTop: 20
    }
  },
  h3: {
    fontSize: 28,
    lineHeight: '37px',
    color: '#2F353F'
  },
  p: {
    fontSize: 18,
    lineHeight: '31px',
    color: '#5A6270'
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    margin: '0 auto'
  },
  table
});

const Aside = styled.aside(props => ({
  display: props.hide ? 'none' : 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: 250,
  maxHeight: '100vh',
  marginTop: -36,
  marginLeft: 30,
  padding: '30px 0px',
  paddingRight: 0,
  position: 'sticky',
  top: 20,
  [breakpoints.lg]: {
    display: 'none'
  },
  [breakpoints.md]: {
    display: props.hide ? 'none' : 'block'
  },
  [breakpoints.sm]: {
    display: 'none'
  }
}));

export default function PageContent(props) {
  const contentRef = useRef(null);
  const [hideSidebar, setHideSidebar] = useState(false);
  useMount(() => {
    if (props.hash) {
      // turn numbers at the beginning of the hash to unicode
      // see https://stackoverflow.com/a/20306237/8190832
      const hash = props.hash.toLowerCase().replace(/^#(\d)/, '#\\3$1 ');
      try {
        const hashElement = contentRef.current.querySelector(hash);
        if (hashElement) {
          hashElement.scrollIntoView();
        }
      } catch (error) {
        // let errors pass
      }
    }
  });

  return (
    <Container>
      <MainContent>
        <HubspotProvider>
          <BodyContent ref={contentRef} className="content-wrapper">
            {props.children}
          </BodyContent>
        </HubspotProvider>
      </MainContent>
      <Aside hide={hideSidebar}>
        <SectionNav
          headings={props.headings}
          contentRef={contentRef}
          title={props.title}
          setHideSidebar={setHideSidebar}
        />
      </Aside>
    </Container>
  );
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  hash: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  headings: PropTypes.array.isRequired
};
