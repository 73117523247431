import React from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { StaticQuery, graphql } from 'gatsby';

function Seo({
  description,
  lang,
  meta,
  keywords,
  title,
  baseUrl,
  socialImage
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <HelmetProvider>
            <Helmet
              htmlAttributes={{
                lang
              }}
              title={`${title} | SebDB`}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              meta={[
                {
                  name: `description`,
                  content: metaDescription
                },
                {
                  name: 'author',
                  content: 'CybSafe Ltd'
                },
                {
                  property: `og:title`,
                  content: `${title} | SebDB`
                },
                {
                  property: `og:image`,
                  content: socialImage
                },
                {
                  property: `og:description`,
                  content: metaDescription
                },
                {
                  property: `og:type`,
                  content: `website`
                },
                {
                  name: `twitter:card`,
                  content: `summary`
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.twitterHandle
                },
                {
                  name: `twitter:title`,
                  content: `${title} | SebDB`
                },
                {
                  name: `twitter:image`,
                  content: `https://cybsafe.com/${socialImage.substring(1)}`
                },
                {
                  name: `twitter:description`,
                  content: metaDescription
                }
              ]
                .concat(
                  keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `)
                      }
                    : []
                )
                .concat(meta)}
            >
              <link
                href="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css"
                rel="stylesheet"
              ></link>
              <script>
                {`
                (function(){
                  window.ldfdr = window.ldfdr || {};
                  (function(d, s, ss, fs){
                    fs = d.getElementsByTagName(s)[0];
                    function ce(src) {
                      var cs = d.createElement(s);
                      cs.src = src;
                      setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1);
                    }
                    ce(ss);
                  })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_ywVkO4X2nGd8Z6Bj.js');
                })();
              `}
              </script>
              <script
                type="text/javascript"
                src="https://cdn-ukwest.onetrust.com/consent/e80033b0-0a5a-42fb-bc37-c61a0430d231/OtAutoBlock.js"
              ></script>
              <script
                src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script="e80033b0-0a5a-42fb-bc37-c61a0430d231"
              ></script>
            </Helmet>
          </HelmetProvider>
        );
      }}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        twitterHandle
      }
    }
  }
`;
