import React, { useEffect, useState } from 'react';
import Slugger from 'github-slugger';
import striptags from 'striptags';
import styled from '@emotion/styled';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import useWindowSize from 'react-use/lib/useWindowSize';
import { colors } from 'gatsby-theme-apollo-core';

const ImageContainer = styled.div({
  img: {
    borderRadius: '8px',
    width: '100%'
  }
});

const StyledList = styled.ul({
  marginLeft: 20,
  marginBottom: 48,
  marginTop: 30
});

const SideBarContainer = styled.div({});

const StyledListItem = styled.li(props => ({
  listStyle: 'none',
  fontSize: '1rem',
  lineHeight: '20px',
  color: props.active && colors.primary,
  a: {
    color: '#5A6270',
    ':hover': {
      opacity: colors.hoverOpacity
    }
  }
}));

export default function SectionNav(props) {
  const [imageSrc, setImageSrc] = useState(null);
  const [allBehaviours, setAllBehaviours] = useState(null);
  const [caseStudyTitles, setCaseStudyTitles] = useState(null);
  const { y } = useWindowScroll();
  const { width, height } = useWindowSize();
  const [offsets, setOffsets] = useState([]);
  const { contentRef, title, setHideSidebar } = props;

  useEffect(() => {
    const h4s = Object.values(contentRef.current.querySelectorAll('h4'))
      .filter(el => el.getAttribute('id') !== null)
      .map(el => {
        return { href: el.getAttribute('id'), title: el.innerText };
      });
    if (h4s.length > 0) {
      setCaseStudyTitles(h4s);
    }
  }, [contentRef]);

  useEffect(() => {
    const dataSrc = contentRef.current.querySelector('[data-src]');
    if (dataSrc) {
      setImageSrc(dataSrc.getAttribute('data-src'));
    } else {
      setHideSidebar(true);
    }
  }, [contentRef, setHideSidebar]);

  useEffect(() => {
    const dataLink = contentRef.current.querySelectorAll('[data-link]');
    if (dataLink) {
      setAllBehaviours(
        Object.values(dataLink).map(el => ({
          href: el.getAttribute('data-link'),
          title: el.getAttribute('title')
        }))
      );
      setOffsets(
        Array.from(dataLink)
          .map(dataLink => {
            return {
              id: dataLink.id,
              offset: dataLink.offsetTop
            };
          })
          .filter(Boolean)
      );
    }
  }, [width, height, contentRef]);

  let activeHeading = null;
  const windowOffset = height / 2;
  const scrollTop = y + windowOffset;
  for (let i = offsets.length - 1; i >= 0; i--) {
    const { id, offset } = offsets[i];
    if (scrollTop >= offset) {
      activeHeading = id;
      break;
    }
  }

  const slugger = new Slugger();
  return imageSrc ? (
    <SideBarContainer>
      <ImageContainer>
        <img src={imageSrc} alt={title} />
      </ImageContainer>
      <StyledList>
        {props.headings.map(({ value }) => {
          const text = striptags(value);
          const slug = slugger.slug(text);
          if (text === 'Case study') {
            return (
              <StyledListItem key={slug} active={slug === activeHeading}>
                <a style={{ fontSize: '18px' }} href={`#${slug}`}>
                  {text}
                </a>
              </StyledListItem>
            );
          } else {
            return null;
          }
        })}
        {caseStudyTitles && (
          <ul>
            {caseStudyTitles.map((el, index) => {
              return (
                <StyledListItem key={index} active={el.href === activeHeading}>
                  <a href={`#${el.href}`}>{el.title}</a>
                </StyledListItem>
              );
            })}
          </ul>
        )}
        {allBehaviours &&
          allBehaviours.map(el => (
            <StyledListItem key={el.title} active={el.href === activeHeading}>
              <a href={`#${el.href}`}>{el.title}</a>
            </StyledListItem>
          ))}
      </StyledList>
    </SideBarContainer>
  ) : null;
}
