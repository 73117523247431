import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const { BEHAVIOURS_URL } = require('../../../constants');

const Heading = styled.h1({
  ':not(:last-child)': {
    marginBottom: 8,
    fontSize: 40,
    lineHeight: '52px',
    color: '#2F353F',
    fontWeight: 600
  }
});

const BreadCrumbContainer = styled.div({
  height: 30,
  marginBottom: 30,
  fontSize: 16,
  lineHeight: '20px',
  color: 'rgba(90,98,112,0.4)',
  a: {
    color: 'rgba(90,98,112,0.4)',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  span: {
    color: '#069991'
  }
});

const Subheading = styled.h2({
  color: '#5A6270',
  fontSize: 22,
  lineHeight: '31px',
  fontFamily: 'IBM Plex Sans'
});

export default function PageHeader(props) {
  const { title, description, description2, description3, pathname } = props;
  const regex = /\/behaviours\/[A-Za-z1-9]/gm;
  const isBehaviourBreadcrumb = regex.exec(pathname);
  return (
    <div className="header-wrapper">
      {isBehaviourBreadcrumb && (
        <BreadCrumbContainer>
          <a href={`${BEHAVIOURS_URL}`}>All Behaviours</a> >{' '}
          <span>{title}</span>
        </BreadCrumbContainer>
      )}
      <Heading>{title}</Heading>
      {description && <Subheading>{description}</Subheading>}
      {description2 && <Subheading>{description2}</Subheading>}
      {description3 && <Subheading>{description3}</Subheading>}
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  description2: PropTypes.string,
  pathname: PropTypes.string
};
